<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="3">
          <b-form-input
            type="text"
            placeholder="Name"
            autocomplete="off"
            v-model="serverParams.name"
            @input="onChange"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { openClose } from "@/shared/options";

export default {
  props: {
    serverParams: Object,
  },
  data: () => ({
    options: {
      openClose,
    },
  }),
  methods: {
    onChange() {
      this.serverParams.pageNumber = 1;
      this.$emit("onChange", this.serverParams);
    },
  },
};
</script>
