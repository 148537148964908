<template>
  <div class="animated fadeIn">
    <CardTable
      :title="title"
      :columns="table.columns"
      :rows="table.rows"
      :pagination="false"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onRowSelected="onRowSelected"
      @onPageChange="onPageChange"
    >
      <template #action>
        <div class="card-header-actions">
          <b-button :to="{ name: 'BuildingManagementCreate' }"
                    size="sm"
                    variant="primary">
            Create New
          </b-button>
        </div>
      </template>
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
    </CardTable>
  </div>
</template>

<script>
import { numberFormat } from "@/shared/utils";
import { buildingManagement as columns } from "@/shared/columns";
import SectionFilter from "./SectionFilter.vue";

export default {
  components: {
    SectionFilter,
  },
  data: () => ({
    title: "Building Management",
    serverParams: {
      pageNumber: 1,
      pageSize: 500,
      keyword: null,
      name: null,
    },
    table: {
      isLoading: true,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  created() {
    const self = this;
    self.getAll();
  },
  methods: {
    numberFormat,
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onRowSelected(items) {
      const self = this;
      self.$router.push({
        name: "BuildingManagementUpdate",
        params: {
          id: items[0].id,
        },
      });
    },
    onPageChange(params) {
      this.updateParams({ pageNumber: params });
      this.getAll();
    },
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: "/buildings",
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
  },
};
</script>
